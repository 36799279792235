<script setup>
import typeOf from 'just-typeof';

const { t } = useI18n({
    locale: 'en',
    messages: {
        en: {
            errors: {
                default: 'An error occured',
            },
        },
    },
});

const props = defineProps({
    error: {
        type: [Boolean, String],
    },
});

const errorMessage = computed(() => {
    const { error } = props;

    if (typeOf(error) === 'string') {
        return error;
    }

    return t('errors.default');
});
</script>

<template>
    <div
        v-if="error"
        class="text-xs md:text-sm text-theme-pink leading-sm mt-1"
    >
        {{ errorMessage }}
    </div>
</template>
